<template>
  <div>
    <v-snackbar
      timeout="5000"
      v-model="moreResultSnackBar"
      color="#171C27"
      top
      @click.native="handleClickMoreResultSnackBar"
      class="cursor-pointer"
    >
      <div class="d-flex flex-row align-item-center" style="position: relative">
        <div
          style="
            background-color: #f05a5c;
            height: 10px;
            width: 10px;
            border-radius: 100%;
            position: absolute;
            top: 0;
            left: 15px;
          "
        ></div>
        <img :src="layerSvg" class="mr-2" />
        <span style="font-weight: bold"> {{ $t("moreResultSnackBar") }}</span>
      </div>
    </v-snackbar>

    <div id="mapContainer" ref="mapContainer" v-once></div>

    <div class="basemap" style="position: relative">
      <DestinationBox
        @handleSelectedFacilities="handleSelectedFacilities"
        @handleClickOnMap="handleClickOnMap"
        @handleRemovePolygon="handleRemovePolygon"
      />
      <v-dialog
        v-model="dialog"
        :fullscreen="isMobileOnly ? true : false"
        transition="dialog-bottom-transition"
        max-width="600px"
        style="z-index: 1002; overflow-x: hidden"
        :scrollable="false"
      >
        <v-card>
          <v-card-title style="padding: 0">
            <v-toolbar flat dark color="primary">
              <v-btn icon dark @click="handleCloseDestinationDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>

          <v-card-text style="padding: 0"
            ><OriginBox
              :type="type"
              @handleCloseOriginDialog="handleCloseDestinationDialog"
          /></v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- shoop info mobile view  -->
      <div v-if="routeDialog && isMobileOnly">
        <v-dialog
          v-model="routeDialog"
          hide-overlay
          style="z-index: 1002; overflow-x: hidden"
          no-click-animation
          max-width="100%"
          persistent
          content-class="my-custom-dialog"
          title="select your destinatnion"
        >
          <v-card tile>
            <v-toolbar flat dark color="white">
              <v-btn icon dark @click="handleCloseRouteDialog" color="#000">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-container style="padding: 10px 30px 30px 30px">
              <v-row justify="start" class="align-item-center">
                <v-col cols="3">
                  <div class="d-flex justify-content-center">
                    <img
                      :src="$store.getters.shopInfo.icon"
                      :alt="$store.getters.shopInfo.title"
                      class="mr-2"
                      width="60"
                      height="60"
                      style="object-fit: contain"
                      v-if="$store.getters.shopInfo.icon"
                    />

                    <v-icon size="30px" v-else>mdi-storefront-outline</v-icon>
                  </div>
                </v-col>
                <v-col cols="9">
                  <div>
                    <div class="d-flex flex-column justify-content-start">
                      <div>
                        <h2 class="text-left">{{ $store.getters.title }}</h2>
                      </div>
                      <div>
                        <h4 class="text-subtitle-2 d-print-block text-left">
                          {{
                            shopInfo.category
                              ? $store.getters.shopInfo.category.name
                              : ""
                          }}
                        </h4>
                      </div>

                      <div
                        class="d-flex flex-row justify-content-start mt-2 mb-5 align-item-center"
                        style="
                          max-width: 85vw;
                          overflow-x: scroll;
                          padding: 25px 0px;
                        "
                        v-if="
                          $store.getters.shopInfo.tags &&
                          $store.getters.shopInfo.tags.length > 0
                        "
                      >
                        <div
                          v-for="tag in $store.getters.shopInfo.tags"
                          :key="tag"
                          @click="handleOnclickTag(tag)"
                          class="cursor-pointer"
                        >
                          <v-badge
                            color="primary"
                            :content="tag"
                            inline
                          ></v-badge>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-btn
                class="mt-5 mb-5 d-flex flex-row w-100 text-center align-item-center flex-row justify-content-center"
                @click="searchStore($store.getters.id, true)"
                color="primary"
              >
                <div class="text-white">
                  <v-icon color="#fff">mdi-arrow-right-top</v-icon>
                </div>
                <div class="text-white">
                  {{ $t("showRoute") }}
                </div>
              </v-btn>
              <v-row>
                <v-col cols="12">
                  <img
                    :src="$store.getters.shopInfo.properties.image"
                    :alt="$store.getters.shopInfo.title"
                    class="mr-2 w-100 br-4"
                    height="180"
                    style="object-fit: fill"
                    v-if="$store.getters.shopInfo.properties.image"
                  />
                </v-col>
                <v-col cols="12">
                  <div v-if="$store.getters.shopInfo.properties.text">
                    <h5 class="text-left text-grey">
                      {{ $store.getters.shopInfo.properties.text }}
                    </h5>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  v-if="$store.getters.shopInfo.properties.phone"
                >
                  <div class="d-flex flex-row">
                    <div>
                      <v-icon color="#171c27">mdi-phone</v-icon>
                    </div>
                    <div class="ml-2">
                      {{ $store.getters.shopInfo.properties.phone }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  v-if="$store.getters.shopInfo.properties.webSite"
                >
                  <div
                    @click="
                      openPlaceWebsiteNewTab(
                        $store.getters.shopInfo.properties.webSite
                      )
                    "
                    class="d-flex flex-row text-decoration-none text-black"
                  >
                    <div>
                      <v-icon color="#171c27">mdi-web</v-icon>
                    </div>
                    <div class="ml-2">
                      <span class="text-black">
                        {{ $store.getters.shopInfo.properties.webSite }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  v-if="$store.getters.shopInfo.properties.workingHours"
                >
                  <div class="d-flex flex-row text-decoration-none text-black">
                    <div>
                      <v-icon color="#171c27">mdi-clock</v-icon>
                    </div>
                    <div class="ml-2">
                      <span class="text-black">
                        {{ $store.getters.shopInfo.properties.workingHours }}
                      </span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="$store.getters.shopInfo.properties.workingHoursWeek">
                <v-col cols="12">
                  <v-list dense>
                    <v-list-item
                      v-for="(day, index) in reorderedWorkingHours"
                      :key="index"
                      class="d-flex justify-space-between pa-0"
                    >
                      <v-list-item-content>
                        <span class="font-weight-bold text-subtitle-2">
                          {{ dayName(day.dayIndexOfWeek) }}
                        </span>
                      </v-list-item-content>
                      <v-list-item-content class="text-caption">
                        <span
                          v-if="!day.closed"
                          class="d-block"
                          style="font-size: 0.7rem !important"
                        >
                          {{ formatTime(day.openTime) }} -
                          {{ formatTime(day.closeTime) }}
                        </span>
                        <span v-else class="text-muted">Closed</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <!-- shop info desktop view -->
    <div
      v-if="$store.getters.shopInfo.title && !isMobileOnly"
      class="shop-info elevation-1"
    >
      <div class="shop-info-content">
        <div class="shop-info-close-icon" @click="handleCloseRouteDialog">
          <v-icon class="fs-24">mdi mdi-close</v-icon>
        </div>
        <v-container>
          <v-row class="align-item-center">
            <v-col cols="4">
              <div class="d-flex flex-column justify-content-start">
                <img
                  :src="$store.getters.shopInfo.icon"
                  :alt="$store.getters.shopInfo.title"
                  class="mr-2"
                  width="60"
                  height="60"
                  style="object-fit: contain"
                  v-if="$store.getters.shopInfo.icon"
                />
                <v-icon v-else>mdi-storefront-outline</v-icon>
              </div>
            </v-col>
            <v-col cols="8">
              <div class="d-flex flex-column">
                <p class="text-start text-h5 mb-1 font-weight-bold">
                  {{ $store.getters.title }}
                </p>
                <span class="d-block text-start">
                  {{
                    shopInfo.category
                      ? $store.getters.shopInfo.category.name
                      : ""
                  }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"></v-divider>
          <v-btn
            class="mt-5 mb-5 d-flex flex-row w-100 text-center align-item-center flex-row justify-content-center"
            @click="searchStore($store.getters.id, true)"
            variant="flat"
            color="primary"
          >
            <div class="text-white">
              <v-icon color="#fff">mdi-arrow-right-top</v-icon>
            </div>

            <div class="text-white">
              {{ $t("showRoute") }}
            </div>
          </v-btn>
          <v-divider
            v-if="$store.getters.shopInfo.properties.image"
          ></v-divider>
          <v-row class="mb-5">
            <v-col cols="12" v-if="$store.getters.shopInfo.properties.image">
              <img
                :src="$store.getters.shopInfo.properties.image"
                :alt="$store.getters.shopInfo.title"
                class="mr-2 w-100 br-4"
                height="180"
                style="object-fit: fill"
              />
            </v-col>
            <v-col cols="12" v-if="$store.getters.shopInfo.properties.text">
              <div>
                <p class="text-left text-grey fs-14">
                  {{ $store.getters.shopInfo.properties.text }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row v-if="$store.getters.shopInfo.tags.length">
            <v-col cols="12" class="mt-5 mb-5">
              <div
                class="d-inline flex-row justify-content-start mt-2 mb-5 align-item-center"
                v-if="$store.getters.shopInfo.tags.length"
              >
                <div
                  class="d-inline cursor-pointer"
                  v-for="tag in $store.getters.shopInfo.tags"
                  :key="tag"
                  @click="handleOnclickTag(tag)"
                >
                  <v-badge color="primary" :content="tag" inline></v-badge>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-5">
            <v-col cols="12" v-if="$store.getters.shopInfo.properties.phone">
              <div class="d-flex flex-row">
                <div>
                  <v-icon color="#171c27">mdi-phone</v-icon>
                </div>
                <div class="ml-2">
                  {{ $store.getters.shopInfo.properties.phone }}
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="$store.getters.shopInfo.properties.webSite">
              <v-btn
                class="mt-5 mb-5 d-flex flex-row w-100 text-center align-item-center flex-row justify-content-center"
                @click="
                  openPlaceWebsiteNewTab(
                    $store.getters.shopInfo.properties.webSite
                  )
                "
                variant="flat"
                color="primary"
              >
                <div class="text-white">
                  <v-icon color="#fff">mdi-web</v-icon>
                </div>

                <div class="text-white">Website</div>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              v-if="$store.getters.shopInfo.properties.workingHours"
            >
              <div class="d-flex flex-row text-decoration-none text-black">
                <div>
                  <v-icon color="#171c27">mdi-clock</v-icon>
                </div>
                <div class="ml-2">
                  <span class="text-black">
                    {{ $store.getters.shopInfo.properties.workingHours }}
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="$store.getters.shopInfo.properties.workingHoursWeek">
            <v-col cols="12">
              <v-list dense>
                <v-list-item
                  v-for="(day, index) in reorderedWorkingHours"
                  :key="index"
                  class="d-flex justify-space-between pa-0"
                >
                  <v-list-item-content>
                    <span class="font-weight-bold text-subtitle-2">
                      {{ dayName(day.dayIndexOfWeek) }}
                    </span>
                  </v-list-item-content>
                  <v-list-item-content class="text-caption">
                    <span
                      v-if="!day.closed"
                      class="d-block"
                      style="font-size: 0.7rem !important"
                    >
                      {{ formatTime(day.openTime) }} -
                      {{ formatTime(day.closeTime) }}
                    </span>
                    <span v-else class="text-muted">Closed</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import DestinationBox from "@/components/DestinationBox.vue";
import OriginBox from "@/components/OriginBox.vue";
import {
  destinationMarkerUrl,
  originMarkerUrl,
  currentLocationMarkerUrl,
} from "../constants/iconUrls";
import { isMobileOnly, isTablet } from "mobile-device-detect";
import * as turf from "@turf/turf";
import { polygonDefaultColorConfig } from "../constants/map";
import { getDegreeBetweenToCoordination } from "../utilities/mapUtilities";

import { postOpenMapEvent, postAnalysesDataAsync } from "../services/services";

export default {
  components: {
    DestinationBox,
    OriginBox,
  },
  props: [
    "placeLoading",
    "centerPoint",
    "floor",
    "route",
    "zoom",
    "clustersList",
    "showClusters",
    "stepper",
    "center",
    "placeTitle",
    "searchDestination",
    "shopInfo",
    "mapStyleUrl",
    "accessToken",
    "disabled",
  ],
  data() {
    return {
      layerSvg: require("../assets/img/layer.svg"),
      dialog: false,
      routeDialog: false,
      moreResultSnackBar: false,
      type: "",
      isMobileOnly: isMobileOnly || isTablet ? true : false,
      showAllResultsOnMap: false,
      filteredStoresList: [],
      map: null,
      currentLocationMarkerRef: null,
      customDestinationMarkerRef: null,
      customOriginMarkerRef: null,
      mapMarker: null,
      mapLoaded: false,
      clearDestinationSearchToggle: false,
      destinationMarker: null,
      destinationPopup: null,
      unclusterPopUp: null,
      routeLength: 0,
      previouslyClickedLayerId: null,
      layerDefaultColor: null,
      userMarkerRotationDegree: 0,
    };
  },
  shouldComponentUpdate() {
    return !this.mapLoaded;
  },
  methods: {
    dayName(dayIndex) {
      const defaultDayNames = [
        this.$t("sunday"),
        this.$t("monday"),
        this.$t("tuesday"),
        this.$t("wednesday"),
        this.$t("thursday"),
        this.$t("friday"),
        this.$t("saturday"),
      ];

      // Find the current dayIndex of the day with `dayIndexOfWeek === 0`
      const zeroIndex = this.workingHoursWeek.findIndex(
        (day) => day.dayIndexOfWeek === 0
      );

      if (zeroIndex === -1) {
        return defaultDayNames[dayIndex]; // Use default order if no zeroIndex is found
      }

      // Rearrange day names so `dayIndexOfWeek === 0` is first
      const reorderedDayNames = [
        ...defaultDayNames.slice(zeroIndex),
        ...defaultDayNames.slice(0, zeroIndex),
      ];

      return reorderedDayNames[dayIndex];
    },
    formatTime(time) {
      // Convert to AM/PM format
      const [hours, minutes] = time.split(":").map(Number);
      const suffix = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for midnight
      return `${formattedHours}:${String(minutes).padStart(2, "0")} ${suffix}`;
    },
    handleClickMoreResultSnackBar() {
      this.$store.dispatch("openFloorsDropDown", true);
    },
    handleOnclickTag(tag) {
      this.$store.dispatch("searchDestination", tag);
      this.$store.dispatch("destinationDialog", true);
      this.$store.dispatch("postSearchData", {
        keyword: tag,
        type: "SEARCH",
      });
    },
    openPlaceWebsiteNewTab(url) {
      window.open(url, "_blank");
    },
    handleCloseDestinationDialog() {
      this.dialog = false;
    },
    async handleCloseRouteDialog() {
      this.routeDialog = false;
      await this.changeClickedPolygonColor(this.previouslyClickedLayerId, null);

      // this.clearSearch();
      // this.$store.dispatch("searchDestination", "");
      if (this.destinationMarker) {
        this.destinationMarker.remove();
        this.destinationMarker = "";
      }
      if (this.destinationPopup) {
        this.destinationPopup.remove();
        this.destinationPopup = "";
      }

      if (this.$store.getters.routeInfo.length > 0) {
        this.$store.dispatch("shopInfo", {
          title: "",
          category: "",
          icon: "",
          properties: {
            categoryId: "",
            image: "",
            phone: "",
            subcategoryId: "",
            text: "",
            webSite: "",
            workingHours: "",
            zoneId: "",
          },
          center: "",
          isCluster: false,
        });
        this.$store.dispatch("setTitle", "");
      } else {
        this.$store.dispatch("reset");
      }
    },
    clearSearch() {
      this.$store.dispatch("reset");
    },
    openDialog(e) {
      this.type = e;
      this.dialog = true;
    },
    openRouteDialog() {
      this.routeDialog = true;
    },
    showRouteButtonText() {
      return this.$i18n.t("showRoute");
    },
    handleSelectedFacilities(faciltiesPointType, title) {
      this.$store.dispatch("routeType", "facility");
      let urlParams = new URLSearchParams(window.location.search);
      const startPoint = urlParams.get("startStoreId");
      const startNodeId = urlParams.get("nodeId");

      let routeStartPoint = "";
      let routeFloor = "";
      let routeCenter = "";

      if (this.destinationPopup) {
        this.destinationPopup.remove();
        this.destinationPopup = "";
      }

      if (startPoint) {
        this.$store.getters.stores.forEach((element) => {
          if (element.filters.store_ids.includes(startPoint)) {
            routeStartPoint = element.id;
            routeFloor = element.location.properties.floorLevel;
            routeCenter = element.location.properties.centerPoint;
          }
        });

        // check of is start point is exist or not

        this.$store.dispatch("getRoute", {
          floor: routeFloor,
          center: routeCenter,
          startPoint: routeStartPoint,
          endPoint: faciltiesPointType,
        });

        this.$store.dispatch("showClusters", false);
        this.$store.dispatch("destinationDialog", false);
      } else if (startNodeId) {
        this.$store.getters.stores.forEach((element) => {
          if (element.id === startNodeId) {
            routeStartPoint = element.id;
            routeFloor = element.location.properties.floorLevel;
            routeCenter = element.location.properties.centerPoint;
          }
        });

        this.$store.dispatch("getRoute", {
          floor: routeFloor,
          center: routeCenter,
          startPoint: routeStartPoint,
          endPoint: faciltiesPointType,
        });
        this.$store.dispatch("showClusters", false);
        this.$store.dispatch("destinationDialog", false);
      } else {
        this.type = faciltiesPointType;
        this.routeDialog = false;
        this.dialog = true;
        this.$store.dispatch("facilitiesTitle", title);
        this.$store.dispatch("shopInfo", {});
        // this.$store.dispatch("setTitle", "");
      }
    },

    searchStore(store, openSearchDialog) {
      let urlParams = new URLSearchParams(window.location.search);
      const startPoint = urlParams.get("startStoreId");
      const startNodeId = urlParams.get("nodeId");

      const hasStartPoint = urlParams.has("startStoreId");
      const hasStartNodeId = urlParams.has("nodeId");

      let routeStartPoint = "";
      let routeFloor = "";
      let routeCenter = "";
      let routeEndPoint = "";
      this.routeDialog = false;
      this.$store.getters.stores.forEach((element) => {
        if (hasStartPoint === false && hasStartNodeId === false) {
          if (element.id === store) {
            let place = {
              title: element.title,
              Id: element.id,
              floor: element.location.properties.floorLevel,
              center: element.location.geometry.coordinates,
            };
            //get found store info
            this.$store.dispatch("getStoreLocation", place);
            const shopIcon = element.category ? element.category.icon : "";
            const tags = element.tags ? element.tags : [];
            const zoneId = element.properties
              ? element.properties.zoneId
                ? element.properties.zoneId
                : ""
              : "";

            this.$store.dispatch("shopInfo", {
              title: element.title,
              category: element.category,
              icon: shopIcon,
              properties: element.properties,
              tags: tags,
              zoneId: zoneId,
              isCluster: this.$store.getters.shopInfo?.isCluster || false,
            });
            // this.$store.dispatch("showClusters", false);
            this.$store.dispatch("destinationDialog", false);
            if (openSearchDialog === true) this.openDialog("search");
          }
        } else if (hasStartPoint === true) {
          if (element.filters.store_ids.includes(startPoint)) {
            routeStartPoint = element.id;
            routeFloor = element.location.properties.floorLevel;
            routeCenter = element.location.properties.centerPoint;
          }
          if (element.id === store) {
            routeEndPoint = element.id;
            const shopIcon = element.category ? element.category.icon : "";
            const tags = element.tags ? element.tags : [];
            const zoneId = element.properties
              ? element.properties.zoneId
                ? element.properties.zoneId
                : ""
              : "";

            this.$store.dispatch("shopInfo", {
              title: element.title,
              category: element.category,
              icon: shopIcon,
              properties: element.properties,
              tags: tags,
              zoneId,
              isCluster: false,
            });
          }
        } else if (hasStartNodeId === true) {
          if (element.id === startNodeId) {
            routeStartPoint = element.id;
            routeFloor = element.location.properties.floorLevel;
            routeCenter = element.location.properties.centerPoint;
          }
          if (element.id === store) {
            routeEndPoint = element.id;
            const shopIcon = element.category ? element.category.icon : "";
            const tags = element.tags ? element.tags : [];
            const zoneId = element.properties
              ? element.properties.zoneId
                ? element.properties.zoneId
                : ""
              : "";

            this.$store.dispatch("shopInfo", {
              title: element.title,
              category: element.category,
              icon: shopIcon,
              properties: element.properties,
              tags: tags,
              zoneId,
              isCluster: false,
            });
          }
        }
      });

      // check of is start point is exist or not
      if (startPoint) {
        this.$store.dispatch("getRoute", {
          floor: routeFloor,
          center: routeCenter,
          startPoint: routeStartPoint,
          endPoint: routeEndPoint,
          routeType: "storeId",
        });
        this.$store.dispatch("showClusters", false);
        this.$store.dispatch("destinationDialog", false);
      }

      if (startNodeId) {
        this.$store.dispatch("getRoute", {
          floor: routeFloor,
          center: routeCenter,
          startPoint: routeStartPoint,
          endPoint: routeEndPoint,
          routeType: "nodeId",
        });
        this.$store.dispatch("showClusters", false);
        this.$store.dispatch("destinationDialog", false);
      }
    },
    initializeMap() {
      if (this.map) {
        return;
      }

      mapboxgl.accessToken = this.accessToken;

      //Create base map
      const bearingDeg = this.$store.getters.urlParamsConfigs.bearing
        ? this.$store.getters.urlParamsConfigs.bearing
        : 0;
      const pitchDeg = this.$store.getters.urlParamsConfigs.pitch
        ? this.$store.getters.urlParamsConfigs.pitch
        : 0;
      const destinationDialogParam =
        this.$store.getters.urlParamsConfigs.destinationDialog === true
          ? true
          : "";

      this.$store.dispatch("destinationDialog", destinationDialogParam);

      this.map = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: this.mapStyleUrl,
        bearing: bearingDeg,
        pitch: pitchDeg,
      });

      if (localStorage.getItem("language") === "ar") {
        mapboxgl.setRTLTextPlugin(
          "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.3.0/mapbox-gl-rtl-text.js"
        );
      }

      this.$store.dispatch("mapRef", this.map);

      const maxZoomLevel =
        this.$store.getters.coordinates[this.floor]?.properties.maxZoomLevel;
      const minZoomLevel =
        this.$store.getters.coordinates[this.floor]?.properties.minZoomLevel;
      const zoomLevel =
        this.$store.getters.coordinates[this.floor]?.properties.zoomLevel;

      const centerPoint =
        this.$store.getters.coordinates[this.floor]?.properties.centerPoint;

      const bounds = [
        [
          this.$store.getters.coordinates[this.floor]?.properties
            .maxBounds[0][1],
          this.$store.getters.coordinates[this.floor]?.properties
            .maxBounds[0][0],
        ],
        [
          this.$store.getters.coordinates[this.floor]?.properties
            .maxBounds[1][1],
          this.$store.getters.coordinates[this.floor]?.properties
            .maxBounds[1][0],
        ],
      ];

      this.map.setCenter(centerPoint);
      this.map.setMaxZoom(maxZoomLevel);
      this.map.setMinZoom(minZoomLevel);
      this.map.setZoom(zoomLevel);
      this.map.zoomTo(zoomLevel, {
        duration: 0,
        center: centerPoint,
      });
      this.map.setMaxBounds(bounds);

      this.map.loadImage(
        "https://cdn-icons-png.flaticon.com/32/2776/2776067.png",
        (error, image) => {
          if (error) throw error;
          this.map.addImage("shop", image);
        }
      );

      postOpenMapEvent();

      // ** handle if url param has multi  store id
    },
    changeClickedPolygonColor(layerId, clickIdProperty) {
      const polygonColor = this.$store.getters.place.data.properties
        ?.clickedColor
        ? this.$store.getters.place.data.properties?.clickedColor
        : polygonDefaultColorConfig;
      const mapLayers = this.map.getStyle().layers;

      mapLayers.forEach((layer) => {
        // Check if the layer is a FillExtrusionStyleLayer

        if (clickIdProperty) {
          if (layer.id === layerId) {
            if (layer.type === "fill-extrusion") {
              const polygonDefaultColor = layer.paint["fill-extrusion-color"];
              this.layerDefaultColor = polygonDefaultColor;
              this.map.setPaintProperty(layer.id, "fill-extrusion-color", [
                "match",
                ["get", "clickId"],
                clickIdProperty,
                polygonColor,
                polygonDefaultColor,
              ]);
            } else if (layer.type === "fill") {
              // Get the layer's color expression

              if (layer.paint) {
                const polygonDefaultColor = layer.paint["fill-color"];
                this.layerDefaultColor = polygonDefaultColor;
                this.map.setPaintProperty(layer.id, "fill-color", [
                  "match",
                  ["get", "clickId"],
                  clickIdProperty,
                  polygonColor,
                  polygonDefaultColor,
                ]);
              }
            }
          }
        } else {
          if (layer.id === layerId) {
            if (layer.type === "fill-extrusion") {
              this.map.setPaintProperty(
                layer.id,
                "fill-extrusion-color",
                this.layerDefaultColor
              );
            } else if (layer.type === "fill") {
              this.map.setPaintProperty(
                layer.id,
                "fill-color",
                this.layerDefaultColor
              );
            }
          }
        }
      });
    },
    handleRemovePolygon() {
      this.changeClickedPolygonColor(this.previouslyClickedLayerId, null);
    },
    async handleClickOnMap(coordinates) {
      let storesList = [];
      if (this.destinationMarker) {
        this.destinationMarker.remove();
        this.destinationMarker = "";
      }
      if (this.destinationPopup) {
        this.destinationPopup.remove();
        this.destinationPopup = "";
      }

      if (this.mapMarker) {
        this.mapMarker.remove();
        this.routeDialog = false;
      }

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
      this.changeClickedPolygonColor(this.previouslyClickedLayerId, null);

      const point = this.$store.getters.mapRef.project(
        coordinates,
        Math.floor(this.map.getZoom())
      );

      const features = this.map.queryRenderedFeatures(point);

      const layerExists =
        this.map.getLayer("selected-polygon-layer") !== undefined;
      const sourceExists =
        this.map.getSource("selected-polygon-source") !== undefined;
      const circleLayersExist =
        this.map.getSource("circle-layer") !== undefined;

      if (layerExists && sourceExists) {
        this.map.removeLayer("selected-polygon-layer");
        this.map.removeSource("selected-polygon-source");
      }

      if (circleLayersExist) {
        this.map.removeLayer("circle-layer");
      }

      let urlParams = new URLSearchParams(window.location.search);

      const hasStartPoint = urlParams.has("startStoreId");
      const hasStartNodeId = urlParams.has("nodeId");

      features.forEach((feature) => {
        if (feature) {
          const featureType = feature.geometry.type;

          if (Object.prototype.hasOwnProperty.call(feature, "properties")) {
            const properties = feature.properties;
            if (
              Object.prototype.hasOwnProperty.call(properties, "isClickable")
            ) {
              this.$store.dispatch("routeType", "click");
              if (properties.isClickable === "TRUE") {
                if (featureType === "Point") {
                  const x = coordinates.lng;
                  const y = coordinates.lat;
                  const selectedPlacePoint = turf.point([x, y]);
                  const searchRadius = 0.005; // radius in kilometers

                  // calculate the distance between the two points using the Turf.js distance function
                  const filterdPlacesAround = [];
                  this.$store.getters.stores.forEach((store) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        store.location.properties,
                        "shopCenterPoint"
                      ) &&
                      store.location.properties.shopCenterPoint
                    ) {
                      let comparePoint = turf.point(
                        store.location.properties.shopCenterPoint
                      );
                      const distance = turf.distance(
                        selectedPlacePoint,
                        comparePoint,
                        {
                          units: "kilometers",
                        }
                      );

                      if (distance < searchRadius) {
                        filterdPlacesAround.push({
                          store: store,
                          distance: distance,
                        });
                      }
                    }
                  });

                  const lowestDistancePlace = filterdPlacesAround.reduce(
                    (prev, curr) => {
                      return prev.distance < curr.distance ? prev : curr;
                    },
                    filterdPlacesAround[0].distance
                  );

                  if (hasStartPoint === false && hasStartNodeId === false) {
                    let place = {
                      title: lowestDistancePlace.store.title,
                      Id: lowestDistancePlace.store.id,
                      floor:
                        lowestDistancePlace.store.location.properties
                          .floorLevel,
                      center:
                        lowestDistancePlace.store.location.geometry.coordinates,
                    };
                    //get found store info
                    this.$store.dispatch("getStoreLocation", place);
                    this.routeDialog = true;

                    const shopIcon = lowestDistancePlace.store.category
                      ? lowestDistancePlace.store.category.icon
                      : "";
                    const tags = lowestDistancePlace.store.tags
                      ? lowestDistancePlace.store.tags
                      : [];
                    const zoneId = lowestDistancePlace.store.properties
                      ? lowestDistancePlace.store.properties.zoneId
                        ? lowestDistancePlace.store.properties.zoneId
                        : ""
                      : "";

                    this.$store.dispatch("shopInfo", {
                      title: lowestDistancePlace.store.title,
                      category: lowestDistancePlace.store.category,
                      icon: shopIcon,
                      properties: lowestDistancePlace.store.properties,
                      tags: tags,
                      zoneId: zoneId,
                      isCluster: false,
                    });

                    this.$store.dispatch("destinationDialog", false);

                    //  i comment because of multi calling route analayses
                    postAnalysesDataAsync({
                      eventKey: "CLICK",
                      eventData: {
                        timestamp: Math.floor(new Date().getTime() / 1000),
                        type: "point",
                        pointId: lowestDistancePlace.store.id,
                      },
                    });
                  } else if (hasStartPoint === true) {
                    const shopIcon = lowestDistancePlace.store.category
                      ? lowestDistancePlace.store.category.icon
                      : "";
                    const tags = lowestDistancePlace.store.tags
                      ? lowestDistancePlace.store.tags
                      : [];
                    const zoneId = lowestDistancePlace.store.properties
                      ? lowestDistancePlace.store.properties.zoneId
                        ? lowestDistancePlace.store.properties.zoneId
                        : ""
                      : "";

                    postAnalysesDataAsync({
                      eventKey: "CLICK",
                      eventData: {
                        timestamp: Math.floor(new Date().getTime() / 1000),
                        type: "point",
                        pointId: lowestDistancePlace.store.id,
                      },
                    });

                    this.$store.dispatch("shopInfo", {
                      title: lowestDistancePlace.store.title,
                      category: lowestDistancePlace.store.category,
                      icon: shopIcon,
                      properties: lowestDistancePlace.store.properties,
                      tags: tags,
                      zoneId,
                      isCluster: false,
                    });
                  }
                } else if (
                  featureType === "Polygon" ||
                  featureType === "MultiPolygon"
                ) {
                  const poly = turf.polygon([feature.geometry.coordinates[0]]);

                  this.$store.getters.stores.forEach((store) => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        store.location.properties,
                        "shopCenterPoint"
                      ) &&
                      store.location.properties.shopCenterPoint &&
                      store?.navigation?.properties?.isVisibleOnMap &&
                      store?.navigation?.properties?.isVisibleOnList
                    ) {
                      let pt = turf.point(
                        store.location.properties.shopCenterPoint
                      );

                      let isInside = turf.booleanPointInPolygon(pt, poly, {
                        ignoreBoundary: false,
                      });
                      const clickIdProperty = feature.properties.clickId
                        ? feature.properties.clickId
                        : "";

                      if (isInside === true) {
                        storesList.push(store);

                        if (clickIdProperty) {
                          const layerId = feature.layer.id;

                          if (this.previouslyClickedLayerId) {
                            this.changeClickedPolygonColor(
                              this.previouslyClickedLayerId,
                              null
                            );
                          }
                          this.changeClickedPolygonColor(
                            layerId,
                            clickIdProperty
                          );

                          this.previouslyClickedLayerId = layerId;
                        } else {
                          const polygonColor = this.$store.getters.place.data
                            .properties?.clickedColor
                            ? this.$store.getters.place.data.properties
                                ?.clickedColor
                            : polygonDefaultColorConfig;
                          this.map.addSource("selected-polygon-source", {
                            type: "geojson",
                            data: {
                              type: "Feature",
                              geometry: {
                                type: "Polygon",
                                coordinates: [feature.geometry.coordinates[0]],
                              },
                            },
                          });
                          this.map.addLayer({
                            id: "selected-polygon-layer",
                            type: "fill",
                            source: "selected-polygon-source",
                            paint: {
                              "fill-color": polygonColor,
                              "fill-opacity": 0.15,
                              "fill-outline-color": polygonColor,
                              // "border-width": 2,
                            },
                          });
                        }

                        let place = {
                          title: store.title,
                          Id: store.id,
                          floor: store.location.properties.floorLevel,
                          center: store.location.geometry.coordinates,
                          polygonCoordinations: feature.geometry.coordinates[0],
                        };
                        //get found store info
                        this.$store.dispatch("getStoreLocation", place);
                        this.routeDialog = true;

                        const shopIcon = store.category
                          ? store.category.icon
                          : "";
                        const tags = store.tags ? store.tags : [];
                        const zoneId = store.properties
                          ? store.properties.zoneId
                            ? store.properties.zoneId
                            : ""
                          : "";

                        this.$store.dispatch("shopInfo", {
                          title: store.title,
                          category: store.category,
                          icon: shopIcon,
                          properties: store.properties,
                          tags: tags,
                          polygonCoordinations: feature.geometry.coordinates[0],
                          zoneId: zoneId,
                          isCluster: false,
                        });

                        this.$store.dispatch("destinationDialog", false);
                      } else {
                        return;
                      }
                    } else {
                      return;
                    }
                  });
                }
              } else {
                return;
              }
            } else {
              return;
            }
          } else {
            console.log("hi");
          }
        } else {
          console.log("hi");
        }
      });

      if (storesList.length > 0) {
        postAnalysesDataAsync({
          eventKey: "CLICK",
          eventData: {
            timestamp: Math.floor(new Date().getTime() / 1000),
            type: "polygon",
            pointId: storesList[storesList.length - 1].id,
          },
        });
      }
    },
    updateMapState() {
      // Draw found point
      if (
        this.$store.getters.title.length > 0 &&
        // this.route.length < 1 &&
        !isMobileOnly
      ) {
        if (this.mapMarker) {
          this.mapMarker.remove();
        }
        if (this.destinationMarker) {
          this.destinationMarker.remove();
        }
        if (this.destinationPopup) {
          this.destinationPopup.remove();
        }
        const shopInfo = this.$store.getters.shopInfo;

        const innerHtmlContent = `
          <div class="align-item-center mt-2  d-flex flex-row justify-content-center w-100 ">
                <div class="d-flex flex-column justify-content-start">
                  <span class="popup-title fw-bold d-block text-left">${
                    shopInfo.title
                  } </span>
                  <span class="fs-12 text--grey d-block text-left ">${
                    shopInfo.category ? shopInfo.category.name : ""
                  } </span>

                </div>
          </div>`;

        let buttonText = this.$i18n.t("showRoute");

        const divElement = document.createElement("div");
        const assignBtn = document.createElement("div");
        assignBtn.style.margin = " 0px";
        assignBtn.style.padding = " 0px";
        assignBtn.innerHTML = `<button class='btn-primary fs-12 fw-bold show-route-popup  d-flex justify-content-center flex-row w-100 text-white' >${buttonText} &nbsp; <span class="mdi mdi-arrow-right-top"></span></button>`;
        divElement.innerHTML = innerHtmlContent;
        // divElement.appendChild(assignBtn);

        const closeIcon = document.createElement("div");
        closeIcon.style.position = "absolute";
        closeIcon.style.top = " 0px";
        closeIcon.style.right = " 0px";
        closeIcon.innerHTML =
          '<span class="cursor-pointer mdi mdi-close fs-18"></span>';

        divElement.appendChild(closeIcon);

        assignBtn.addEventListener("click", async () => {
          await this.changeClickedPolygonColor(
            this.previouslyClickedLayerId,
            null
          );

          let urlParams = new URLSearchParams(window.location.search);
          const startPoint = urlParams.get("startStoreId");
          let routeStartPoint = "";
          let routeFloor = "";
          let routeCenter = "";

          if (startPoint) {
            this.$store.getters.stores.forEach((element) => {
              if (element.filters.store_ids.includes(startPoint)) {
                routeStartPoint = element.id;
                routeFloor = element.location.properties.floorLevel;
                routeCenter = element.location.properties.centerPoint;
              }
            });

            // check of is start point is exist or not

            this.$store.dispatch("getRoute", {
              floor: routeFloor,
              center: routeCenter,
              startPoint: routeStartPoint,
              endPoint: this.$store.state.Id,
            });
            this.$store.dispatch("showClusters", false);
            this.$store.dispatch("destinationDialog", false);
          } else {
            this.openDialog("search");
          }
        });

        if (!this.destinationMarker) {
          if (shopInfo.isCluster === false) {
            this.destinationMarker = new mapboxgl.Marker({
              color: "#39A1FF",

              draggable: false,
              optimized: false,
            })
              .setLngLat(this.centerPoint)
              .addTo(this.map);
          }

          this.destinationPopup = new mapboxgl.Popup({
            closeOnClick: false,
            offset: 35,
          })
            .setLngLat(this.centerPoint)
            .setDOMContent(divElement)
            .addTo(this.map);
        } else {
          this.destinationPopup.remove();
          if (shopInfo.isCluster === false) {
            this.destinationMarker = new mapboxgl.Marker({
              color: "#39A1FF",
              draggable: false,
              optimized: false,
            })
              .setLngLat(this.centerPoint)
              .addTo(this.map);
          }
          this.destinationPopup = new mapboxgl.Popup({
            closeOnClick: false,
            offset: 35,
          })
            .setLngLat(this.centerPoint)
            .setDOMContent(divElement)
            .addTo(this.map);
        }

        const bounds = [
          [
            this.$store.getters.coordinates[this.floor]?.properties
              .maxBounds[0][1],
            this.$store.getters.coordinates[this.floor]?.properties
              .maxBounds[0][0],
          ],
          [
            this.$store.getters.coordinates[this.floor]?.properties
              .maxBounds[1][1],
            this.$store.getters.coordinates[this.floor]?.properties
              .maxBounds[1][0],
          ],
        ];

        this.map.setMaxBounds(bounds);
        this.map.easeTo({
          center: this.centerPoint,
          zoom: this.zoom,
        });
        this.$store.dispatch("zoom", this.zoom);
        this.$store.dispatch("center", this.centerPoint);

        closeIcon.addEventListener("click", () => {
          this.handleCloseRouteDialog();
          this.changeClickedPolygonColor(this.previouslyClickedLayerId, null);
          const layerExists =
            this.map.getLayer("selected-polygon-layer") !== undefined;
          const sourceExists =
            this.map.getSource("selected-polygon-source") !== undefined;
          const circleLayersExist =
            this.map.getSource("circle-layer") !== undefined;

          if (this.previouslyClickedLayerId) {
            this.changeClickedPolygonColor(this.previouslyClickedLayerId, null);
          }

          if (this.destinationMarker) {
            this.destinationMarker.remove();
            this.destinationMarker = "";
          }
          if (this.destinationPopup) {
            this.destinationPopup.remove();
            this.destinationPopup = "";
          }

          if (layerExists || sourceExists) {
            this.map.removeLayer("selected-polygon-layer");
            this.map.removeSource("selected-polygon-source");
          }

          if (circleLayersExist) {
            this.map.removeLayer("circle-layer");
          }
        });
      } else if (this.$store.getters.title.length > 0 && isMobileOnly) {
        if (this.mapMarker) {
          this.mapMarker.remove();
        }
        this.mapMarker = new mapboxgl.Marker({
          color: "#39A1FF",
          draggable: false,
          optimized: false,
        })
          .setLngLat(this.centerPoint)
          .addTo(this.map);

        this.openRouteDialog();

        const bounds = [
          [
            this.$store.getters.coordinates[this.floor]?.properties
              .maxBounds[0][1],
            this.$store.getters.coordinates[this.floor]?.properties
              .maxBounds[0][0],
          ],
          [
            this.$store.getters.coordinates[this.floor]?.properties
              .maxBounds[1][1],
            this.$store.getters.coordinates[this.floor]?.properties
              .maxBounds[1][0],
          ],
        ];

        this.map.setMaxBounds(bounds);
        this.map.easeTo({
          center: this.centerPoint,
          zoom: this.zoom,
        });
      }

      if (!this.$store.getters.title.length && isMobileOnly) {
        if (this.mapMarker) {
          this.mapMarker.remove();
        }
      }
    },
    handleFocusParams() {
      // handle zoom level if url param has params on url
      const focusStoreIdParam = this.$store.getters.urlParamsConfigs
        .focusStoreId
        ? this.$store.getters.urlParamsConfigs.focusStoreId
        : "";

      const zoomLevelParam = this.$store.getters.urlParamsConfigs.zoomLevel
        ? this.$store.getters.urlParamsConfigs.zoomLevel
        : "";
      const focusCoordsParam = this.$store.getters.urlParamsConfigs.focusCoords
        ? this.$store.getters.urlParamsConfigs.focusCoords
        : "";
      let centerPointFocusStoreIdParam = "";

      let urlParams = new URLSearchParams(window.location.search);
      const startPoint = urlParams.get("startStoreId");
      const hasStartPoint = urlParams.has("startStoreId");

      if (hasStartPoint && this.$store.getters.placesLoading !== true) {
        if (startPoint) {
          this.$store.getters.stores.forEach((element) => {
            if (element.filters.store_ids.includes(startPoint)) {
              const focusCoordsParamPoint = turf.point([
                element.location.geometry.coordinates[0],
                element.location.geometry.coordinates[1],
              ]);

              const mapBounds = this.map.getBounds();
              const mapBoundsGeometry = turf.polygon([
                [
                  [mapBounds.getNorthWest().lng, mapBounds.getNorthWest().lat],
                  [mapBounds.getNorthEast().lng, mapBounds.getNorthEast().lat],
                  [mapBounds.getSouthEast().lng, mapBounds.getSouthEast().lat],
                  [mapBounds.getSouthWest().lng, mapBounds.getSouthWest().lat],
                  [mapBounds.getNorthWest().lng, mapBounds.getNorthWest().lat],
                ],
              ]);

              const isInsideBound = turf.booleanPointInPolygon(
                focusCoordsParamPoint,
                mapBoundsGeometry,
                {
                  ignoreBoundary: false,
                }
              );

              if (isInsideBound === true) {
                this.map.zoomTo(zoomLevelParam, {
                  duration: 0,
                  center: [
                    element.location.geometry.coordinates[0],
                    element.location.geometry.coordinates[1],
                  ],
                });
              } else {
                this.$store.getters.floors.forEach((floor) => {
                  const floorLineString = turf.lineString([
                    [
                      floor.properties.maxBounds[0][1],
                      floor.properties.maxBounds[0][0],
                    ],
                    [
                      floor.properties.maxBounds[1][1],
                      floor.properties.maxBounds[1][0],
                    ],
                  ]);
                  const floorBoundsBbox = turf.bbox(floorLineString);
                  const floorPolygon = turf.bboxPolygon(floorBoundsBbox);

                  const isPointInsideFloorPolygon = turf.booleanPointInPolygon(
                    focusCoordsParamPoint,
                    floorPolygon,
                    {
                      ignoreBoundary: false,
                    }
                  );

                  if (isPointInsideFloorPolygon) {
                    this.$store.dispatch(
                      "changeFloor2",
                      element.location.properties.floorLevel
                    );
                  }
                });
              }
            }
          });
        }
      }

      if (focusStoreIdParam && this.$store.getters.placesLoading !== true) {
        this.$store.getters.stores.forEach((element) => {
          if (element.filters.store_ids.includes(focusStoreIdParam)) {
            centerPointFocusStoreIdParam =
              element.location.properties.shopCenterPoint;
          }
        });

        if (centerPointFocusStoreIdParam) {
          this.map.zoomTo(zoomLevelParam, {
            duration: 0,
            center: centerPointFocusStoreIdParam,
          });
        }
      }

      if (
        focusCoordsParam.latitude &&
        this.$store.getters.placesLoading !== true
      ) {
        const focusCoordsParamPoint = turf.point([
          focusCoordsParam.latitude,
          focusCoordsParam.longitude,
        ]);

        const mapBounds = this.map.getBounds();
        const mapBoundsGeometry = turf.polygon([
          [
            [mapBounds.getNorthWest().lng, mapBounds.getNorthWest().lat],
            [mapBounds.getNorthEast().lng, mapBounds.getNorthEast().lat],
            [mapBounds.getSouthEast().lng, mapBounds.getSouthEast().lat],
            [mapBounds.getSouthWest().lng, mapBounds.getSouthWest().lat],
            [mapBounds.getNorthWest().lng, mapBounds.getNorthWest().lat],
          ],
        ]);
        const isInsideBound = turf.booleanPointInPolygon(
          focusCoordsParamPoint,
          mapBoundsGeometry,
          {
            ignoreBoundary: false,
          }
        );

        if (isInsideBound === true) {
          this.map.zoomTo(zoomLevelParam, {
            duration: 0,
            center: [focusCoordsParam.latitude, focusCoordsParam.longitude],
          });
        } else {
          this.$store.getters.floors.forEach((floor) => {
            const floorLineString = turf.lineString([
              [
                floor.properties.maxBounds[0][1],
                floor.properties.maxBounds[0][0],
              ],
              [
                floor.properties.maxBounds[1][1],
                floor.properties.maxBounds[1][0],
              ],
            ]);
            const floorBoundsBbox = turf.bbox(floorLineString);
            const floorPolygon = turf.bboxPolygon(floorBoundsBbox);

            const isPointInsideFloorPolygon = turf.booleanPointInPolygon(
              focusCoordsParamPoint,
              floorPolygon,
              {
                ignoreBoundary: false,
              }
            );
            if (isPointInsideFloorPolygon) {
              this.$store.dispatch("focusCoordsParamsInfo", floor);
              this.$store.dispatch("changeFloor2", floor.level);
            }
          });
        }
      }
    },

    handleRotationOfcurrentLocationMarkerRef(direction = "forward") {
      const stepperSeconCord =
        direction === "forward" ? this.stepper + 1 : this.stepper;

      const stepperFirstCord =
        direction === "forward" ? this.stepper : this.stepper - 1;

      this.userMarkerRotationDegree = getDegreeBetweenToCoordination(
        {
          latitude:
            this.$store.getters.routeInfo[stepperFirstCord].coordinates[1],
          longitude:
            this.$store.getters.routeInfo[stepperFirstCord].coordinates[0],
        },
        {
          latitude:
            this.$store.getters.routeInfo[stepperSeconCord].coordinates[1],
          longitude:
            this.$store.getters.routeInfo[stepperSeconCord].coordinates[0],
        }
      );

      this.currentLocationMarkerRef.setRotation(this.userMarkerRotationDegree);
    },
  },

  async mounted() {
    if (!this.map) {
      this.initializeMap();
    }

    this.updateMapState();

    if (this.navigation === true) {
      this.map.on("click", (e) => {
        this.handleClickOnMap({
          lng: e.lngLat.lng,
          lat: e.lngLat.lat,
        });
      });
    }
  },

  watch: {
    stepper: function () {
      if (this.currentLocationMarkerRef) {
        this.currentLocationMarkerRef.setLngLat(
          this.$store.getters.routeInfo[this.stepper].coordinates
        );
      }

      const navigationZoomLevel = this.$store.getters.coordinates[
        this.$store.getters.floor
      ].properties.navigationZoomLevel
        ? this.$store.getters.coordinates[this.$store.getters.floor].properties
            .navigationZoomLevel
        : 19;

      if (this.floor !== this.$store.getters.routeInfo[this.stepper].floor) {
        this.$store.dispatch(
          "changeFloor",
          this.$store.getters.routeInfo[this.stepper].floor
        );
      } else {
        this.map.flyTo({
          center: this.$store.getters.routeInfo[this.stepper].coordinates,
          zoom: navigationZoomLevel,
          speed: 0.1,
          curve: 1,
          duration: 200,
          essential: true,
          easing(t) {
            return t;
          },
        });
      }

      let direction = "";
      if (this.stepper > this.prevStepper) {
        direction = "forward";
      } else if (this.stepper < this.prevStepper) {
        direction = "backward";
      }

      // Update the previous stepper value
      this.prevStepper = this.stepper;

      if (this.stepper === 0 || this.stepper === 1) {
        this.handleRotationOfcurrentLocationMarkerRef("forward");
      } else if (
        this.$store.getters.routeInfo[this.stepper + 1].floor === this.floor &&
        direction === "forward"
      ) {
        this.handleRotationOfcurrentLocationMarkerRef("forward");
      } else if (
        direction === "backward" &&
        this.$store.getters.routeInfo[this.stepper + 1].floor === this.floor
      ) {
        this.handleRotationOfcurrentLocationMarkerRef("forward");
      } else if (
        this.$store.getters.routeInfo[this.stepper + 1].floor !== this.floor &&
        direction === "backward"
      ) {
        this.handleRotationOfcurrentLocationMarkerRef("backward");
      }

      this.$store.dispatch("zoom", navigationZoomLevel);
    },

    floor: function () {
      const maxZoomLevel =
        this.$store.getters.coordinates[this.floor]?.properties.maxZoomLevel;
      const minZoomLevel =
        this.$store.getters.coordinates[this.floor]?.properties.minZoomLevel;
      const zoomLevel =
        this.$store.getters.coordinates[this.floor]?.properties.zoomLevel;

      const centerPoint =
        this.$store.getters.coordinates[this.floor]?.properties.centerPoint;
      const zoomLevelParam = this.$store.getters.urlParamsConfigs.zoomLevel
        ? this.$store.getters.urlParamsConfigs.zoomLevel
        : "";
      const focusCoordsParam = this.$store.getters.urlParamsConfigs.focusCoords
        ? this.$store.getters.urlParamsConfigs.focusCoords
        : "";

      const bounds = [
        [
          this.$store.getters.coordinates[this.floor]?.properties
            .maxBounds[0][1],
          this.$store.getters.coordinates[this.floor]?.properties
            .maxBounds[0][0],
        ],
        [
          this.$store.getters.coordinates[this.floor]?.properties
            .maxBounds[1][1],
          this.$store.getters.coordinates[this.floor]?.properties
            .maxBounds[1][0],
        ],
      ];

      if (!this.route.length) {
        this.map.setMaxBounds(bounds); // Set bounds first
        this.map.setMinZoom(minZoomLevel); // Apply constraints
        this.map.setMaxZoom(maxZoomLevel);
        this.map.setCenter(centerPoint); // Update center
        this.map.setZoom(zoomLevel); // Update zoom
      } else {
        const navigationZoomLevel = this.$store.getters.coordinates[
          this.$store.getters.floor
        ].properties.navigationZoomLevel
          ? this.$store.getters.coordinates[this.$store.getters.floor]
              .properties.navigationZoomLevel
          : 19;
        this.map.setMaxBounds(bounds, {
          center: centerPoint,
          zoomLevel: zoomLevel,
          minZoomLevel: minZoomLevel,
          maxZoomLevel: maxZoomLevel,
        });
        if (this.floor !== this.$store.getters.routeInfo[this.stepper].floor) {
          this.$store.getters.floors.forEach((floor, index) => {
            if (index === this.floor) {
              this.map.setCenter(floor.properties.centerPoint);
              this.map.zoomTo(floor.properties.zoomLevel);
            }
          });
        } else {
          this.map.zoomTo(navigationZoomLevel, {
            duration: 0,
            center: this.$store.getters.routeInfo[this.stepper].coordinates,
          });
        }
      }

      if (this.$store.getters.showClusters === true) {
        let coordinatesArray = [];
        const currentFloorInfo =
          this.$store.getters.floors[this.$store.getters.floor];

        this.$store.getters.clustersList.forEach((item) => {
          if (currentFloorInfo.level === item.location.properties.floorLevel) {
            coordinatesArray.push([
              parseFloat(item.location.geometry.coordinates[0]),
              parseFloat(item.location.geometry.coordinates[1]),
            ]);
          }
        });

        this.mapLoaded = true;

        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          coordinatesArray[0],
          coordinatesArray[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinatesArray) {
          bounds.extend(coord);
        }

        this.map.fitBounds(bounds, {
          padding: 80,
        });
      }

      if (
        this.$store.getters.focusCoordsParamsInfo !== null &&
        focusCoordsParam !== null
      ) {
        this.map.setZoom(zoomLevelParam);
        this.map.setCenter([
          focusCoordsParam.latitude,
          focusCoordsParam.longitude,
        ]);
        this.$store.dispatch("focusCoordsParamsInfo", null);
      }
    },

    route: function () {
      const placeConfig = this.$store.getters.placeConfig;

      if (this.destinationMarker) {
        this.destinationMarker.remove();
        this.destinationMarker = "";
      }

      if (this.destinationPopup) {
        this.destinationPopup.remove();
        this.destinationPopup = "";
      }

      if (this.route.length > 0) {
        if (this.destinationPopup) {
          this.destinationPopup.remove();
          this.destinationPopup = "";
        }
        this.dialog = false;
        this.routeDialog = false;
        const navigationZoomLevel = this.$store.getters.coordinates[
          this.$store.getters.floor
        ].properties.navigationZoomLevel
          ? this.$store.getters.coordinates[this.$store.getters.floor]
              .properties.navigationZoomLevel
          : 19;

        this.routeLength = this.route.length;

        this.route.forEach((item, index) => {
          this.map.addSource("route" + index, {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: item,
              },
            },
          });
          this.map.addLayer({
            id: "route" + index,
            type: "line",
            source: "route" + index,

            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": placeConfig.routeColor
                ? placeConfig.routeColor
                : "#66BB6A",
              "line-width": 8,
              ...(placeConfig.routeStyle &&
                placeConfig.routeStyle === "dashed" && {
                  "line-dasharray": [1, 2],
                }),
              ...(placeConfig.routeStyle &&
                placeConfig.routeStyle === "dotted" && {
                  "line-dasharray": [0.00003, 2],
                }),
            },
          });
        });

        const a = this.route.length - 1;
        const b = this.route[a].length - 1;

        //**  destination and origin marker

        const customDestinationMarker = document.createElement("img");
        const customOriginMarker = document.createElement("img");

        customDestinationMarker.src = destinationMarkerUrl;
        customDestinationMarker.style.width = `40px`;
        customDestinationMarker.style.height = `40px`;
        customDestinationMarker.style.objectFit = `contain`;
        customDestinationMarker.style.backgroundSize = "cover";

        customOriginMarker.src = originMarkerUrl;
        customOriginMarker.style.width = `30px`;
        customOriginMarker.style.height = `30px`;
        customOriginMarker.style.objectFit = `contain`;
        customOriginMarker.style.backgroundSize = "cover";

        this.customDestinationMarkerRef = new mapboxgl.Marker({
          anchor: "bottom",
          draggable: false,
          optimized: false,
          element: customDestinationMarker,
        })
          .setLngLat(this.route[a][b])
          .addTo(this.map);

        this.customOriginMarkerRef = new mapboxgl.Marker({
          draggable: false,
          optimized: false,
          element: customOriginMarker,
        })
          .setLngLat(this.route[0][0])
          .addTo(this.map);

        const currentLocationMarker = document.createElement("img");

        currentLocationMarker.id = "currentLocationMarker";
        currentLocationMarker.src = placeConfig.userMarkerImage
          ? placeConfig.userMarkerImage
          : currentLocationMarkerUrl;
        currentLocationMarker.style.width = `45px`;
        currentLocationMarker.style.height = `45px`;
        currentLocationMarker.style.objectFit = `contain`;
        currentLocationMarker.style.backgroundSize = "cover";

        this.currentLocationMarkerRef = new mapboxgl.Marker({
          element: currentLocationMarker,
          draggable: false,
          optimized: false,
          rotation: 0,
        })
          .setLngLat(this.$store.getters.routeInfo[this.stepper].coordinates)
          .addTo(this.map);

        this.handleRotationOfcurrentLocationMarkerRef("forward");

        if (window.screen.width <= 2000) {
          this.map.flyTo({
            center: this.$store.getters.routeInfo[this.stepper].coordinates,
            zoom: navigationZoomLevel,
            speed: 0.1,
            curve: 1,
            duration: 200,
            essential: true,
            easing(t) {
              return t;
            },
          });
        } else {
          const bounds = new mapboxgl.LngLatBounds(this.route[0]);

          // this.map.setCenter(this.route[0][0]);

          // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
          for (const coord of this.route[0]) {
            bounds.extend(coord);
          }

          this.map.fitBounds(bounds, {
            padding: 80,
          });
        }
      } else {
        this.routeDialog = false;

        for (let index = 0; index < this.routeLength; index++) {
          this.map.removeLayer("route" + index);
          this.map.removeSource("route" + index);
        }
        if (this.currentLocationMarkerRef)
          this.currentLocationMarkerRef.remove();
        if (this.customDestinationMarkerRef)
          this.customDestinationMarkerRef.remove();
        if (this.customOriginMarkerRef) this.customOriginMarkerRef.remove();
        if (this.mapMarker) this.mapMarker.remove();
        if (this.customOriginMarkerRef) this.customOriginMarkerRef.remove();

        if (this.destinationMarker) {
          this.destinationMarker.remove();
          this.destinationMarker = "";
        }
        if (this.destinationPopup) {
          this.destinationPopup.remove();
          this.destinationPopup = "";
        }
        this.routeLength = 0;
      }
    },

    shopInfo: function () {
      this.updateMapState();
    },

    showClusters: function () {
      if (this.$store.getters.showClusters === true) {
        let coordinatesArray = [];
        const currentFloorInfo = this.$store.getters.focusCoordsParamsInfo
          ? this.$store.getters.focusCoordsParamsInfo
          : this.$store.getters.floors[this.$store.getters.floor];

        if (
          this.$store.getters.clustersList.some(
            (cluster) =>
              cluster.location.properties.floorLevel === currentFloorInfo.level
          ) === true
        ) {
          console.log("dsadsa");
        } else {
          let clusterQuantityArray = [];
          this.$store.getters.floors.forEach((floor) => {
            clusterQuantityArray.push({ ...floor, clusterCount: 0 });
            this.$store.getters.clustersList.forEach((cluster) => {
              if (cluster.location.properties.floorLevel === floor.level) {
                const objIndex = clusterQuantityArray.findIndex(
                  (item) =>
                    item.level === cluster.location.properties.floorLevel
                );

                clusterQuantityArray[objIndex].clusterCount =
                  clusterQuantityArray[objIndex].clusterCount + 1;
              }
            });
          });
          clusterQuantityArray.sort(function (a, b) {
            return b.clusterCount - a.clusterCount;
          });

          const maxClusterCountFloorInfo = clusterQuantityArray.reduce(
            (prev, current) =>
              prev.clusterCount >= current.clusterCount ? prev : current
          );

          const clusterFloorBounds = [
            [
              maxClusterCountFloorInfo.properties.maxBounds[0][1],
              maxClusterCountFloorInfo.properties.maxBounds[0][0],
            ],
            [
              maxClusterCountFloorInfo.properties.maxBounds[1][1],
              maxClusterCountFloorInfo.properties.maxBounds[1][0],
            ],
          ];

          this.$store.dispatch("changeFloor2", maxClusterCountFloorInfo.level);
          this.map.setMaxBounds(clusterFloorBounds);
        }

        const points = this.$store.getters.clustersList.map((item) => {
          if (currentFloorInfo.level === item.location.properties.floorLevel) {
            coordinatesArray.push([
              parseFloat(item.location.geometry.coordinates[0]),
              parseFloat(item.location.geometry.coordinates[1]),
            ]);
          }

          return {
            type: "Feature",
            properties: {
              id: item.id,
              title: item.title,
              floorLevel: item.location.properties.floorLevel,
            },
            geometry: {
              coordinates: [
                parseFloat(item.location.geometry.coordinates[0]),
                parseFloat(item.location.geometry.coordinates[1]),
              ],
              type: "Point",
            },
            id: item.id,
          };
        });

        // this.map.on("load", () => {
        this.mapLoaded = true;
        this.map.addSource("storesCluster", {
          type: "geojson",
          data: {
            features: points,
            type: "FeatureCollection",
          },
          cluster: true,
        });

        this.map.addLayer({
          id: "clusters",
          type: "circle",
          source: "storesCluster",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#1BBEFA",
              100,
              "#f1f075",
              750,
              "#f28cb1",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              25,
              100,
              40,
              1000,
              70,
            ],
          },
        });

        this.map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "storesCluster",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 15,
          },
        });

        this.map.addLayer({
          id: "unclustered-point",
          type: "symbol",
          source: "storesCluster",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "icon-image": "shop",
          },
        });

        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          coordinatesArray[0],
          coordinatesArray[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinatesArray) {
          bounds.extend(coord);
        }

        this.map.fitBounds(bounds, {
          padding: 80,
        });

        this.map.on("click", "unclustered-point", (e) => {
          e.originalEvent.stopPropagation();

          const clusterStoreId = e.features[0].properties.id;

          this.$store.getters.stores.forEach((element) => {
            if (element.id === clusterStoreId) {
              let place = {
                title: element.title,
                Id: element.id,
                floor: element.location.properties.floorLevel,
                center: element.location.geometry.coordinates,
              };
              //get found store info
              this.$store.dispatch("getStoreLocation", place);
              this.routeDialog = true;
              const shopIcon = element.category ? element.category.icon : "";
              const tags = element.tags ? element.tags : [];
              const zoneId = element.properties
                ? element.properties.zoneId
                  ? element.properties.zoneId
                  : ""
                : "";

              this.$store.dispatch("shopInfo", {
                title: element.title,
                category: element.category,
                icon: shopIcon,
                properties: element.properties,
                tags: tags,
                zoneId: zoneId,
                isCluster: true,
              });

              // this.$store.dispatch("showClusters", false);

              this.$store.dispatch("destinationDialog", false);
            }
          });
        });

        this.map.on("mouseenter", "clusters", () => {
          this.map.getCanvas().style.cursor = "pointer";
        });
        this.map.on("mouseleave", "clusters", () => {
          this.map.getCanvas().style.cursor = "";
        });
        this.map.on("mouseenter", "unclustered-point", () => {
          this.map.getCanvas().style.cursor = "pointer";
        });
        this.map.on("mouseleave", "unclustered-point", () => {
          this.map.getCanvas().style.cursor = "";
        });

        // inspect a cluster on click
        this.map.on("click", "clusters", (e) => {
          e.originalEvent.stopPropagation();

          const features = this.map.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });

          const clusterId = features[0].properties.cluster_id;

          this.map
            .getSource("storesCluster")
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              this.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
        });
        // });
      } else {
        if (
          this.map.getLayer("clusters") !== undefined &&
          this.map.getLayer("cluster-count") !== undefined &&
          this.map.getLayer("unclustered-point") !== undefined &&
          this.map.getSource("storesCluster") !== undefined
        ) {
          this.map.removeLayer("clusters");
          this.map.removeLayer("cluster-count");
          this.map.removeLayer("unclustered-point");
          this.map.removeSource("storesCluster");
        }
      }

      if (this.showClusters == true) {
        const currentFloorInfo =
          this.$store.getters.floors[this.$store.getters.floor];

        const triggerMoreResultSnackBar = this.$store.getters.clustersList.some(
          (cluster) =>
            cluster.location.properties.floorLevel !== currentFloorInfo.level
        );
        this.moreResultSnackBar = triggerMoreResultSnackBar;
      } else {
        this.moreResultSnackBar = false;
      }
    },

    mapStyleUrl: function () {
      this.map.setStyle(this.mapStyleUrl);
      this.updateMapState();
    },

    placeLoading: function () {
      this.handleFocusParams();
    },
  },

  computed: {
    workingHoursWeek() {
      return this.$store.getters.shopInfo.properties.workingHoursWeek;
    },
    reorderedWorkingHours() {
      // Find the index of the day with dayIndexOfWeek === 0
      const zeroIndex = this.workingHoursWeek.findIndex(
        (day) => day.dayIndexOfWeek === 0
      );

      if (zeroIndex === -1) {
        return this.workingHoursWeek; // Return original order if no zero is found
      }

      // Reorder the array to put dayIndexOfWeek === 0 at the top
      return [
        this.workingHoursWeek[zeroIndex],
        ...this.workingHoursWeek.slice(0, zeroIndex),
        ...this.workingHoursWeek.slice(zeroIndex + 1),
      ];
    },
    loading() {
      return this.$store.getters.loading;
    },
    navigation() {
      const paramsNavigationConfig =
        this.$store.getters.urlParamsConfigs.navigation;
      const dataNavigationConfig = this.$store.getters.navigation;

      if (paramsNavigationConfig === false && dataNavigationConfig === true)
        return false;
      return dataNavigationConfig;
    },
  },
};
</script>

<style lang="scss" scoped>
.basemap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0px;
}
</style>

<style>
#mapContainer {
  width: 100vw;
  height: 100vh;
  margin: 0px;
}
.shop-info {
  position: absolute;
  left: 15px;
  top: 80px;
  max-height: 80vh;
  z-index: 9;
  width: 300px;
  background-color: #fff;
  border-radius: 4px;
}
.shop-info-close-icon {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}
.shop-info-content {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 80vh;
  padding: 0px 20px;
}
.marker {
  touch-action: none; /* disable all touch gestures on the marker */
}

.popup {
  touch-action: pan-x pan-y; /* enable panning but disable pinch-to-zoom on the popup */
}
.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", Sans-serif;
  padding: 0;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.mapboxgl-popup-content-wrapper {
  padding: 1%;
}

.mapboxgl-popup-content h3 {
  background: #91c949;
  color: #fff;
  margin: 0;
  display: block;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
  margin-top: -15px;
}

.mapboxgl-popup-content h4 {
  margin: 0;
  display: block;
  font-weight: 400;
}

.mapboxgl-popup-content div {
  padding: 5px;
}

.btn-primary {
  background: #14a1ff;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 2px 2px 5px;
}
.mapboxgl-ctrl-bottom-left {
  display: none !important;
}
.my-custom-dialog {
  align-self: flex-end;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  background-color: #fff;
}
.h6Class {
  font-size: 12px;
  text-align: left;
}
.my-popup {
  padding: 0px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  border: 1px solid #ccc;
}
.show-route-popup {
  margin: 0px;
}
.popup-title {
  font-size: 14px;
}
</style>
, currentLocationMarkerUrl
